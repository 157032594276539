<template>
	<div class="navmb">
		<div class="flex flexa">
			<span class="">控制台中心</span>
		</div>
	</div>

	<p class="h20"></p>
	<div class="m-mainbox clearfix">
		<div class="col-l">
			<!-- 知识库文档 -->
			<div class="g-boxb1">
				<div class="g-titb1">
					<div class="left">
						<div class="h3">知识库文档</div>
					</div>
					<div class="right" @click="$router.push({
						path: '/knowledge/docSearch'
					})">
						<div class="more">查看更多</div>
					</div>
				</div>
				<ul class="g-tabsb1">
					<li :class="{ active: s_doclist.type == 3 }" @click="s_doclist.type = 3">
						<div class="con">搜索和浏览</div>
					</li>
					<li :class="{ active: s_doclist.type == 1 }" @click="getDocListByType(1)">
						<div class="con">最新更新</div>
					</li>
					<li :class="{ active: s_doclist.type == 2 }" @click="getDocListByType(2)">
						<div class="con">最近查看</div>
					</li>
					<li :class="{ active: s_doclist.type == 4 }" @click="getDocListByCollect(4)">
						<div class="con">收藏的文档</div>
					</li>
				</ul>

				<div class="m-chobox" v-loading="s_doclist.loading">
					<template v-if="s_doclist.type == 3">
						<div class="line">
							<div class="type">选择所属分类和文档类型</div>
							<div class="gp df">
								<div class="item">
									<el-cascader collapse-tags v-model="search.categoryId" class="w100" size="large"
										:props="s_doclist.props" :options="s_doclist.options" clearable
										placeholder="选择文档所属分类" />
								</div>
								<div class="item">
									<el-select size="large" v-model="search.cateId" placeholder="选择文档类型">
										<el-option label="Bug文档" :value="1" />
										<el-option label="知识文档" :value="2" />
									</el-select>
								</div>
							</div>
						</div>
						<div class="line">
							<div class="type">输入关键词搜索</div>

							<el-input size="large" v-model="search.docTitle" placeholder="输入文档的名称、文档描述等" @keydown.enter="$router.push({
						path: '/knowledge/docSearch',
						state: {
							docTitle: search.docTitle,
							docType: search.docType,
							categoryId: search.categoryId.join(','),
						}
					})">
								<template #suffix>
									<el-icon color="#FF6A00" class="point" @click="$router.push({
						path: '/knowledge/docSearch',
						state: {
							docTitle: search.docTitle,
							docType: search.docType,
							categoryId: search.categoryId.join(','),
						}
					})">
										<Search />
									</el-icon>
								</template>
							</el-input>
						</div>
						<div class="line" v-show="false">
							<div class="type">搜索历史</div>
							<ul class="ul-hisb1">
								<li v-for="(item, index) in searchList" :key="index" @click="$router.push({
						path: '/knowledge/docSearch',
						state: {
							docTitle: item,
							docType: search.docType,
							categoryId: search.categoryId,
						}
					})">
									<div class="a">{{ item }}</div>
								</li>
								<li v-if="$store.getters['searchStore/get_search'].length" class="hisitem"
									@click="$store.commit('searchStore/clearSearch')">
									<div style="color: #337eff" class="a">清除历史</div>
								</li>
							</ul>
						</div>
					</template>
					<template v-else-if="s_doclist.type == 4">
						<el-scrollbar height="400px" noresize>
							<template v-if="s_doclist_maplist.length">
								<div v-for="(item, index) in s_doclist_maplist" :key="index">
									<div class="flex flexsb marb10">
										<div class="flex flexc lh24 marb10">
											<el-icon @click="clickDelete(item.id)" color="#ff6a00" size="16"
												class="marr20 point">
												<StarFilled />
											</el-icon>
											<p class="colHtext" type="primary" @click="$router.push({
						path: `/knowledgeInfo/${item.id}`
					})">{{ item.docTitle }}{{ item.docNumber ? `(${item.docNumber})` : '' }}</p>
										</div>
										<p class="col9c">{{ item.createTime }}</p>
									</div>
								</div>
							</template>
							<el-empty v-else description="暂无数据">
								<template #image>
									<img style="object-fit: none" :src="require('../../assets/imgs/empty.png')" alt=""
										srcset="">
								</template>
							</el-empty>
						</el-scrollbar>
					</template>
					<template v-else-if="s_doclist.type == 1">
						<el-scrollbar height="400px" noresize>
							<template v-if="s_doclist_maplist.length">
								<div v-for="(item, index) in s_doclist_maplist" :key="index">
									<div class="font12 lh20 marb20">{{ item.dayStr }}</div>
									<ul class="font12 lh20">
										<li class="flex flexa lh24 marb10" v-for="(_item, _index) in item.docListDtos"
											:key="_index" @click="$router.push({
						path: `/knowledgeInfo/${_item.id}`
					})">
											<p class="col9c marr20">{{ _item.createTime }}</p>
											<p class="colHtext" type="primary">{{ _item.docTitle
												}}{{ _item.docNumber ? `(${_item.docNumber})` : '' }}</p>
										</li>
									</ul>
								</div>
							</template>
							<el-empty v-else description="暂无数据">
								<template #image>
									<img style="object-fit: none" :src="require('../../assets/imgs/empty.png')" alt=""
										srcset="">
								</template>
							</el-empty>
						</el-scrollbar>
					</template>
					<template v-else-if="s_doclist.type == 2">
						<el-scrollbar height="400px" noresize>
							<template v-if="s_doclist_maplist.length">
								<div v-for="(item, index) in s_doclist_maplist" :key="index">
									<div class="font12 lh20 marb20">{{ item.dayStr }}</div>
									<ul class="font12 lh20">
										<li class="flex flexa lh24 marb10" v-for="(_item, _index) in item.docListDtos"
											:key="_index" @click="$router.push({
						path: `/knowledgeInfo/${_item.id}`
					})">
											<p class="col9c marr20">{{ _item.createTime }}</p>
											<p class="colHtext" type="primary">{{ _item.docTitle
												}}{{ _item.docNumber ? `(${_item.docNumber})` : '' }}</p>
										</li>
									</ul>
								</div>
							</template>
							<el-empty v-else description="暂无数据">
								<template #image>
									<img style="object-fit: none" :src="require('../../assets/imgs/empty.png')" alt=""
										srcset="">
								</template>
							</el-empty>
						</el-scrollbar>
					</template>
				</div>
			</div>
			<!--服务请求 -->
			<div class="g-boxb1">
				<div class="g-titb1">
					<div class="left">
						<div class="h3">服务请求</div>
					</div>
					<div class="right" @click="$router.push({ path: '/tech/list/' + tech.param.type })">
						<div class="more">全部请求</div>
					</div>
				</div>
				<ul class="g-tabsb1" style="margin-bottom:0">
					<li :class="{ active: tech.param.type == 2 }" @click="tech.param.type = 2; serviceRequest(1)">
						<div class="con">技术服务请求</div>
					</li>
					<li :class="{ active: tech.param.type == 1 }" @click="tech.param.type = 1; serviceRequest(1)">
						<div class="con">非技术服务请求</div>
					</li>
				</ul>
				<div class="flexab m-chotop flexw" style="margin-bottom:0">
					<div class="left mart20">
						<el-button v-if="tech.param.type == 2" size="large" @click="$router.push('/submit/tech')"
							type="primary">+创建服务请求</el-button>
						<el-button v-else-if="tech.param.type == 1" size="large" @click="$router.push('/submit/untech')"
							type="primary">+创建服务请求</el-button>
						<el-select size="large" @change="serviceRequest(1)" v-model="tech.param.csi" clearable
							placeholder="客户服务号">
							<el-option v-for="item in csiList" :key="item.id"
								:label="item.csiNumber + '（' + item.companyName + '）'" :value="item.id" />
						</el-select>
					</div>
					<div class="flex flexa mart20">
						<div class="wmax padr30">
							<el-checkbox-group v-model="searchType" @change="queryServiceRequest()">
								<el-checkbox :label=1 :value=1>我收藏的</el-checkbox>
								<el-checkbox class="marl20" :label=2 :value=2>我名下的</el-checkbox>
								<el-checkbox class="marl20" :label=3 :value=3>进行中的</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
				</div>
				<div class="m-flexbox">
					<div class="m-leftbox flexw">
						<div class="item mart20">
							<div class="g-sel_inp">
								<el-select size="large" @change="serviceRequest()" v-model="tech.param.searchKey"
									placeholder="问题概要">
									<el-option label="技术请求编号" value="outTradeNo" />
									<el-option label="问题概要" value="problemProfile" />
								</el-select>
								<el-input size="large" v-model="tech.param[tech.param.searchKey]" placeholder="搜索关键字">
									<template #suffix>
										<el-icon color="#FF6A00" @click="serviceRequest()" class="el-input__icon point">
											<Search />
										</el-icon>
									</template>
								</el-input>
							</div>
						</div>
						<div class="flex mart20">
							<div class="item">
								<div class="g-sel_inp">
									<el-button size="large" class="w68" style="margin-right: -1px" @click="() => {
						tech.param.time = []
						tech.param.createTime = ''
						tech.param.endTime = ''
						serviceRequest()
					}">全部</el-button>
									<el-date-picker v-model="tech.param.time" format="YYYY-MM-DD"
										value-format="YYYY-MM-DD" type="daterange" range-separator="至"
										start-placeholder="开始时间" end-placeholder="结束时间" size="large" @change="(val) => {
						const [createTime, endTime] = val ?? []
						tech.param.createTime = createTime
						tech.param.endTime = endTime
						serviceRequest()
					}">
									</el-date-picker>
								</div>
							</div>
							<div class="opts">
								<div class="set">
									<el-icon @click="field.visible = true">
										<img style="width: 1em;height:1em" draggable="false"
											src="../../assets/imgs/setting.png" />
									</el-icon>
								</div>
								<div class="refresh">
									<el-icon @click="tech.param = {
						searchKey: 'problemProfile',
						type: '2',
						csi: '',
						problemProfile: '',
						none: '',
						pageNumber: 1,
						pageSize: 10
					}, searchType = [], serviceRequest()">
										<img style="width: 1em;height:1em" draggable="false"
											src="../../assets/imgs/refersh.png" />
									</el-icon>
								</div>
							</div>
						</div>
					</div>
				</div>
				<FieldFilter v-model:visible="field.visible" v-model:list="field.list" :options="field.options"
					@confirm="filterFiled_Confirm" />
				<!-- 表格 -->
				<el-table class="g-tableb1" :data="tech.list" style="width: 100%" v-loading="tech.loading">
					<el-table-column fixed label="问题概要" class-name="padl20 padr20"
						:show-overflow-tooltip="{ effect: 'light' }" min-width="234px"
						v-if="map_filedOptions.has('problemProfile')">
						<template #="{ row }">
							<div class="flex padr14">
								<router-link @click="toServiceInfo(row)" class="bllk block text1 col4c" to="/">{{
						row.problemProfile }}</router-link>
							</div>
						</template>
					</el-table-column>
					<el-table-column v-if="map_filedOptions.has('outTradeNo')" prop="outTradeNo" fixed min-width="120"
						label="技术请求编号" :show-overflow-tooltip="{ effect: 'light' }">
					</el-table-column>
					<el-table-column v-if="map_filedOptions.has('productModelName')" prop="productModelName"
						min-width="200" label="产品模块" :show-overflow-tooltip="{ effect: 'light' }">
						<template #="{ row }">
							{{row.productName}} > {{row.productModelName}}
						</template>
					</el-table-column>
					<el-table-column v-if="map_filedOptions.has('createTime')" prop="createTime" min-width="180"
						sortable :show-overflow-tooltip="{ effect: 'light' }" label="创建时间">
					</el-table-column>
					<el-table-column min-width="180" prop="lastUpdateTime" label="上次更新时间"
						:show-overflow-tooltip="{ effect: 'light' }">
					</el-table-column>

					<el-table-column v-if="map_filedOptions.has('u')" min-width="160" prop="u" sortable label="严重等级"
						:filters="[
						{ text: 'u1', value: 1 },
						{ text: 'u2', value: 2 },
						{ text: 'u3', value: 3 },
						{ text: 'u4', value: 4 },
					]" :filter-method="(val, row, col) => row[col['property']] == val" filter-placement="bottom-end">
						<template #="{ row }">
							<div class="severity lev1" v-if="row.u == 1">
								{{ u[row.u] }}
							</div>
							<div class="severity lev2" v-else-if="row.u == 2">
								{{ u[row.u] }}
							</div>
							<div class="severity lev3" v-else-if="row.u == 3">
								{{ u[row.u] }}
							</div>
							<div class="severity lev4" v-else-if="row.u == 4">
								{{ u[row.u] }}
							</div>
						</template>
					</el-table-column>
					<el-table-column v-if="map_filedOptions.has('mainContact')" prop="mainContact" label="联系人"
						:show-overflow-tooltip="{ effect: 'light' }" min-width="80">
					</el-table-column>

					<el-table-column v-if="map_filedOptions.has('userStatus')" prop="userStatus" label="状态"
						:show-overflow-tooltip="{ effect: 'light' }" min-width="70" :filters="[
						{ text: '待分派', value: 1 },
						{ text: '支持工作中', value: 2 },
						{ text: '客户工作中', value: 3 },
						{ text: '复查更新', value: 4 },
						{ text: '研发工作中', value: 5 },
						{ text: '待研发反馈', value: 6 },
						{ text: '待内部反馈', value: 7 },
						{ text: '方案已提供', value: 8 },
						{ text: '用户请求关闭', value: 9 },
						{ text: '已关闭', value: 10 },
						{ text: '重开审核中', value: 11 },
					]" :filter-method="(val, row, col) => row[col['property']] == val" filter-placement="bottom-end">
						<template #="{ row }">
							{{ userStatus.get(row.userStatus) }}
						</template>
					</el-table-column>

					<el-table-column v-if="map_filedOptions.has('csi')" prop="csi" label="客户服务号"
						:show-overflow-tooltip="{ effect: 'light' }" min-width="120px">
					</el-table-column>
					<el-table-column prop="isCollect" label="收藏" min-width="70">
						<template #="{ row }">
							<div class="point">
								<el-button v-if="row.isCollect == 0" link :disabled="row.disableLoading ?? false"
									@click="collectOrder(row)">
									<template #icon>
										<el-icon size="16">
											<Star />
										</el-icon>
									</template>
								</el-button>
								<el-button v-else-if="row.isCollect == 1" link :disabled="row.disableLoading ?? false"
									@click="collectOrder(row)">
									<template #icon>
										<el-icon color="#ff6a00" size="16">
											<StarFilled />
										</el-icon>
									</template>
								</el-button>
							</div>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="100">
						<template #="{ row }">
							<span @click="toServiceInfo(row)" class="point colblue font12">查看</span>
						</template>
					</el-table-column>
				</el-table>
				<div class="h70 pad20 flexab w100 borbox">
					<p class="font14 cola4">共 {{ tech.total }} 条</p>
					<el-pagination v-model:currentPage="tech.param.pageNumber" @update:page-size="val => {
						tech.param.pageSize = val;
						serviceRequest()
					}" @update:current-page="serviceRequest" :page-sizes="[5, 10, 15, 20]" :page-size="tech.param.pageSize"
						layout="sizes, prev , jumper , next" :total="tech.total">
					</el-pagination>
				</div>
			</div>
		</div>
		<div class="col-r" style="min-width:340px;flex:1">
			<!-- 账号信息 -->
			<div class="g-boxb1">
				<div class="g-titb1">
					<div class="left">
						<div class="h3">账号信息</div>
					</div>
					<div class="right" @click="$router.push({
						path: '/account'
					})">
						<div class="more">账号管理</div>
					</div>
				</div>
				<div class="m-userbox">
					<div class="user">
						<div class="img" :style="'background-image: url(' +
						userInfo.headPic +
						');'
						"></div>
						<div class="txt">
							<div class="name">{{ userInfo.username }}</div>
							<div class="id">
								账号ID：<span>{{ userInfo.userNo }}</span><el-icon @click="copyText(userInfo.userNo)">
									<DocumentCopy />
								</el-icon>
							</div>
						</div>
					</div>
					<div class="r">
						<div class="info">
							已绑定的CSI：<em>{{ csiCount || 0 }}个</em><router-link to="/account" class="btn">管理</router-link>
						</div>
					</div>
				</div>
			</div>
			<!-- 服务请求数据 -->
			<div class="g-boxb1">
				<div class="g-titb1">
					<div class="left">
						<div class="h3">服务请求数据</div>
					</div>
				</div>
				<ul class="ul-listb1 tc">
					<li @click="$router.push({
						name: 'tech/list',
						params: {
							type: 2
						},
						state: {
							force: '/tech/list/2'
						},
						query: {
							status: 3
						},
					})">
						<div class="con">
							<div class="p">客户工作中</div>
							<div class="num">{{ statusInfo.userWorkTotal ?? '-' }}</div>
						</div>
					</li>
					<li @click="$router.push({
						name: 'tech/list',
						params: {
							type: 2
						},
						state: {
							force: '/tech/list/2'
						},
						query: {
							status: 2
						}
					})">
						<div class="con">
							<div class="p">支持工作中</div>
							<div class="num">{{ statusInfo.supportWorkTotal ?? '-' }}</div>
						</div>
					</li>
					<li @click="$router.push({
						name: 'tech/list',
						params: {
							type: 2
						},
						state: {
							force: '/tech/list/2'
						},
						query: {
							status: ''
						}
					})">
						<div class="con">
							<div class="p">全部工单</div>
							<div class="num">{{ statusInfo.orderTotal ?? '-' }}</div>
						</div>
					</li>
				</ul>
			</div>
			<!-- 海量资讯 -->
			<div class="g-boxb1">
				<div class="g-titb1">
					<div class="left">
						<div class="h3">海量资讯</div>
					</div>
					<div class="right" @click="$router.push({
						path: '/news/list'
					})">
						<div class="more">查看更多</div>
					</div>
				</div>
				<ul class="g-tabsb1">
					<li :class="{ active: dynamics.tabIndex == 1 }" @click="announcementDynamics(1)">
						<div class="con">最新公告</div>
					</li>
					<li :class="{ active: dynamics.tabIndex == 2 }" @click="announcementDynamics(2)">
						<div class="con">产品动态</div>
					</li>
				</ul>
				<div class="tabbox">
					<ul class="ul-listb2" v-loading="dynamics.loading" v-if="dynamics.loading || dynamics.list.length">
						<li v-for="(item, index) in dynamics.list" :key="index">
							<router-link v-if="item.docType == 1" class="con" :to="{
						name: 'newsDetail',
						params: {
							id: item.id
						}
					}">
								<div class="tit">{{ item.title }}</div>
								<div class="date">{{ item.createTime }}</div>
							</router-link>
							<router-link v-else-if="item.docType == 2" class="con" :to="{
						name: 'knowledgeInfo',
						params: {
							id: item.docNumber
						}
					}">
								<div class="tit">{{ item.title }}</div>
								<div class="date">{{ item.createTime }}</div>
							</router-link>
						</li>
					</ul>
					<el-empty v-else description="暂无数据">
						<template #image>
							<img style="object-fit: none" :src="require('../../assets/imgs/empty.png')" alt=""
								srcset="">
						</template>
					</el-empty>
				</div>
			</div>
			<!-- 学习课堂 -->
			<div class="g-boxb1">
				<div class="g-titb1">
					<div class="left">
						<div class="h3">学习课堂</div>
					</div>
					<div class="right" @click="$router.push({
						path: '/news/lists'
					})">
						<div class="more">查看更多</div>
					</div>
				</div>
				<ul class="g-tabsb1">
					<li :class="{ active: learning.tabIndex == 3 }" @click="learningClassroom(3)">
						<div class="con">入门学习</div>
					</li>
					<li :class="{ active: learning.tabIndex == 4 }" @click="learningClassroom(4)">
						<div class="con">新手指导</div>
					</li>
					<li :class="{ active: learning.tabIndex == 2 }" @click="learningClassroom(2)">
						<div class="con">常见问题</div>
					</li>
				</ul>
				<div class="tabbox">
					<ul class="ul-listb3" v-loading="learning.loading" v-if="learning.loading || learning.list.length">
						<li v-for="(item, index) in learning.list" :key="index">
							<div class="con">
								<router-link :to="`/news/details/${item.articleId}`" class="link">{{ item.title
									}}</router-link>
							</div>
						</li>
					</ul>
					<el-empty v-else description="暂无数据">
						<template #image>
							<img style="object-fit: none" :src="require('../../assets/imgs/empty.png')" alt=""
								srcset="">
						</template>
					</el-empty>
				</div>
			</div>
			<!-- 海量技术支持微信端 -->
			<div class="g-boxb1">
				<div class="m-txtb1">
					<div class="txt">
						<div class="h3">海量技术支持微信端</div>
						<div class="p">关注技术支持微信H5端，及时了解工单进度信息~</div>
					</div>
					<div class="img">
						<img src="../../assets/imgs/ma2.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { DocumentCopy, Search, StarFilled, Star } from '@element-plus/icons-vue'
import FieldFilter from '@/components/FieldFilter.vue'
import { markRaw } from "vue"
import { mapGetters } from 'vuex'
import {
	accountBaseInfo,
} from "@/utils/api1.js"
export default {
	setup() {
		return {
			userStatus: new Map([
				[1, '待分派'],
				[2, '支持工作中'],
				[3, '客户工作中'],
				[4, '复查更新'],
				[5, '研发工作中'],
				[6, '待研发反馈'],
				[7, '待内部反馈'],
				[8, '方案已提供'],
				[9, '用户请求关闭'],
				[10, '已关闭'],
				[11, '重开审核中'],
			]),
			u: markRaw({ 4: 'U4-服务未丢失', 3: 'U3-服务少量丢失', 2: 'U2-服务严重丢失', 1: 'U1-服务完全丢失' })
		}
	},
	components: {
		DocumentCopy, Search, Star, StarFilled, FieldFilter
	},
	computed: {
		...mapGetters({
			searchList: 'searchStore/get_search',
			csiList: 'cache/getCsiList',
		}),
		s_doclist_maplist() {
			return this.s_doclist.mapList.get(this.s_doclist.type) ?? []
		},
		map_filedOptions() {
			return new Set(this.field.confirmList)
		}
	},
	data() {
		return {
			searchType: [],
			userInfo: {},
			csiCount: 0,
			search: {
				categoryId: '',
				docType: '',
				docTitle: ''
			},
			tech: {
				param_form: {
					csi: '',
					searchType: [],
					time: '',
					searchKey: 'problemProfile',
				},
				param: {
					searchKey: 'problemProfile',
					type: '2',
					searchType: '',
					csi: '',
					problemProfile: '',
					none: '',
					pageNumber: 1,
					pageSize: 10
				},
				loading: false,
				list: [],
				total: 0
			},
			field: {
				visible: false,
				list: ['problemProfile', 'outTradeNo', 'createTime', 'u', 'mainContact', 'userStatus', 'csi', 'productModelName'],
				confirmList: ['problemProfile', 'outTradeNo', 'createTime', 'u', 'mainContact', 'userStatus', 'csi', 'productModelName'],
				options: [
					{ value: 'problemProfile', label: '问题概要', readonly: false },
					{ value: 'outTradeNo', label: '技术请求编号', readonly: false },
					{ value: 'productModelName', label: '产品模块', readonly: true },
					{ value: 'createTime', label: '创建时间', readonly: false },
					{ value: 'u', label: '严重等级', readonly: false },
					{ value: 'mainContact', label: '联系人', readonly: false },
					{ value: 'userStatus', label: '状态', readonly: false },
					{ value: 'csi', label: '客户服务号', readonly: false },
				]
			},
			// 状态
			info: {},
			dynamics: {
				list: [],
				tabIndex: 3,
				loading: false,
			},
			learning: {
				list: [],
				tabIndex: 3,
				loading: false,
			},
			s_doclist: {
				typeIndex: '',
				cateId: '',
				type: '3',
				mapList: new Map(),
				loading: false,
				props: {
					value: 'id',
					multiple: true,
					label: 'categoryTitle',
					children: 'list',
					checkStrictly: false,
					emitPath: false,
				},
				options: [],
			},
			statusInfo: {},
		}
	},
	mounted() {
		this.loadFilterFiled()
		this.$store.dispatch('cache/getCsiList')
		this.serviceRequest()
		this.learningClassroom(3)
		this.announcementDynamics(1)
		this.serviceCount()
		this.docCateList()
		this.getUserInfo()
		// window.scrollTo(0,0)
	},
	methods: {
		clickDelete(id) {
			// this.$api.know.collectDoc({id}).then(res=>{
			// 	console.log('取消收藏返回',res)
			// })
		},
		getUserInfo() {
			let that = this
			accountBaseInfo().then(res => {
				that.userInfo = res
				that.csiCount = res.csiCount
			})
		},
		loadFilterFiled() {
			try {
				this.field.confirmList = this.field.list = JSON.parse(localStorage.getItem('fieldList')) ?? ['problemProfile', 'outTradeNo', 'createTime', 'u', 'mainContact', 'userStatus', 'csi', 'productModelName']
			} catch {
				this.field.list = ['problemProfile', 'outTradeNo', 'createTime', 'u', 'mainContact', 'userStatus', 'csi', 'productModelName']
				this.field.confirmList = ['problemProfile', 'outTradeNo', 'createTime', 'u', 'mainContact', 'userStatus', 'csi', 'productModelName']
			}
		},
		filterFiled_Confirm(event) {
			this.field.confirmList = event
			localStorage.setItem('fieldList', JSON.stringify(event))
		},
		async docCateList() {
			try {
				const { records, total } = await this.$api.know.docCateList({
					pageNumber: 1,
					pageSize: 2000
				})
				this.s_doclist.options = records
			} catch (e) {
				return console.log(e)
			}
		},
		copyText(value) {
			const input = document.createElement('input');
			input.value = value; // 设置要复制的文本内容
			// 将输入框添加到页面中
			document.body.appendChild(input);
			// 选中输入框的内容
			input.select();
			// 执行复制操作
			document.execCommand('copy');
			// 移除临时创建的输入框
			document.body.removeChild(input);
			this.$message.success('复制成功')
		},
		async serviceCount() {
			try {
				const result = await this.$api.tech.serviceCount({
					csi: '',
					userStatus: '',
					type: '',
					searchType: '',
					pageNumber: 1,
					pageSize: 9999,
				})
				this.statusInfo = result
			} catch (e) {
				return console.log(e)
			} finally {

			}
		},
		async getDocListByCollect(type) {
			try {
				this.s_doclist.type = type
				this.s_doclist.loading = true
				this.s_doclist.mapList.set(type,
					this.s_doclist.mapList.get(type) ?? (
						await this.$api.know.getDocListByCollect({ type })
					).records
				)
			} catch (e) {
				return console.log(e)
			} finally {
				this.s_doclist.loading = false
			}
		},
		async getDocListByType(type) {
			try {
				this.s_doclist.type = type
				this.s_doclist.loading = true
				this.s_doclist.mapList.set(type,
					this.s_doclist.mapList.get(type) ?? (
						await this.$api.know.getDocListByType({ type })
					)
				)
			} catch (e) {
				return console.log(e)
			} finally {
				this.s_doclist.loading = false
			}
		},
		async announcementDynamics(type) {
			try {
				this.dynamics.tabIndex = type
				this.dynamics.loading = true
				const { records, total } = await this.$api.base.announcementDynamics({
					type,
					pageNumber: 1,
					pageSize: 3
				})
				this.dynamics.list = records
			} catch (e) {
				return console.log(e)
			} finally {
				this.dynamics.loading = false
			}
		},
		async learningClassroom(type) {
			try {
				this.learning.tabIndex = type
				this.learning.loading = true
				const { records, total } = await this.$api.base.learningClassroom({
					type,
					pageNumber: 1,
					pageSize: 3
				})
				console.log('学习课堂返回', records)
				this.learning.list = records
			} catch (e) {
				return console.log(e)
			} finally {
				this.learning.loading = false
			}
		},
		queryServiceRequest() {
			// if (this.tech.param_form.searchType.length > 1) {
			// 	this.tech.param_form.searchType.splice(0, 1)
			// }
			this.tech.param.csi = this.tech.param_form.csi
			this.tech.param.searchType = this.searchType ? this.searchType.join(',') : ''
			this.tech.param.createTime = this.tech.param_form.createTime
			this.tech.param.endTime = this.tech.param_form.endTime
			this.tech.param[this.tech.param_form.searchKey] = this.tech.param_form[this.tech.param_form.searchKey]
			this.serviceRequest(1)
		},
		async serviceRequest(pageNumber) {
			try {
				pageNumber && (this.tech.param.pageNumber = pageNumber)
				this.tech.loading = true
				const _param = {
					createTime: this.tech.param.createTime,
					endTime: this.tech.param.endTime,
					csi: this.tech.param.csi,
					pageNumber: this.tech.param.pageNumber,
					pageSize: this.tech.param.pageSize,
					searchType: this.tech.param.searchType,
					type: this.tech.param.type,
					problemProfile: this.tech.param.problemProfile || '',
					outTradeNo: this.tech.param.outTradeNo || ''

				}
				const { records, total } = await this.$api.tech.serviceRequest(_param)
				this.tech.list = records
				this.tech.total = total
			} catch (e) {
				return console.log(e)
			} finally {
				this.tech.loading = false
			}
		},
		async collectOrder(row) {
			try {
				row.disableLoading = true
				const result = await this.$api.order.collectOrder({ id: row.id })
				if (result) {
					this.$message.success(result.message)
					if (row.isCollect == 0) {
						row.isCollect = 1
					} else if (row.isCollect == 1) {
						row.isCollect = 0
					}
				}
			} catch (e) {
				return console.log(e)
			} finally {
				row.disableLoading = false
			}
		},
		toServiceInfo(row) {
			this.$router.push({
				name: 'serviceInfo',
				params: {
					id: row.id
				}
			})
		},
	}
}
</script>
<style lang="scss" scoped>
.w300 {
	width: 300px;
}

.colHtext {
	color: #151822;
	transition: .4s;

	&:hover {
		transition: .4s;
		color: #ff6a00;
		cursor: pointer;
	}
}

.m-mainbox {
	display: flex;

	.col-l {
		//float: left;
		width: 65%;
		margin-right: 16px;
	}

	.col-r {
		overflow: hidden;
	}
}

.m-userbox {
	display: flex;
	align-items: center;
	padding: 15px 0;

	.user {
		flex: 1;
		overflow: hidden;
		display: flex;
		align-items: center;
	}

	.name {
		font-size: 19px;
		color: #343434;
		font-weight: 500;
		margin-bottom: 12px;
	}

	.id {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #7b7b7b;

		span {
			color: #343434;
		}

		.el-icon {
			cursor: pointer;
			margin-left: 5px;
			color: #ff6a00;
		}
	}

	.img {
		border-radius: 50%;
		width: 80px;
		height: 80px;
		display: block;
		overflow: hidden;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		margin-right: 12px;
	}

	.r {
		flex-shrink: 0;
		overflow: hidden;
	}

	.info {
		font-size: 14px;
		color: #7b7b7b;

		em {
			color: #ff6a00;
			font-style: normal;
		}

		.btn {
			color: #0091ea;
			margin-left: 19px;
		}
	}
}

.ul-listb1 {
	overflow: hidden;
	margin: 0 -8px;
	padding: 14px 0;

	li {
		float: left;
		width: 33.33%;

		&:nth-child(1) {
			.num {
				color: #0091ea;
			}
		}
	}

	.con {
		display: block;
		cursor: pointer;
		background: #f7f7f7;
		border-radius: 5px;
		overflow: hidden;
		margin: 0 8px;
		font-size: 14px;
		color: #676767;
		padding: 14px 10px 20px 10px;
		box-sizing: border-box;
	}

	.p {
		margin-bottom: 7px;
	}

	.num {
		font-size: 28px;
		color: #3c3c3c;
		font-weight: 500;
		font-family: Roboto;
	}
}

.ul-listb2 {
	min-height: 120px;

	.con {
		cursor: pointer;
		display: block;
		margin-bottom: 20px;

		&:hover {
			.tit {
				color: #ff6a00;
			}
		}
	}

	.tit {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		font-size: 12px;
		line-height: 17px;
		color: #333;
		transition: 0.4s;
		margin-bottom: 8px;
	}

	.date {
		font-size: 12px;
		color: #808080;
		line-height: 17px;
		padding-left: 20px;
		background: url(../../assets/imgs/c-i1.svg) no-repeat left top;
		background-size: 14px;
	}
}

.ul-listb3 {
	min-height: 120px;

	.con {
		margin-bottom: 27px;
	}

	.link {
		display: block;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		font-size: 12px;
		color: #303030;
		transition: 0.4s;
		padding-left: 10px;
		position: relative;

		&::before {
			content: "";
			display: block;
			width: 4px;
			height: 4px;
			background: #989898;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -2px;
			transition: 0.4s;
		}

		&:hover {
			color: #ff6a00;

			&::before {
				background-color: #ff6a00;
			}
		}
	}
}

.m-txtb1 {
	display: flex;
	align-items: center;
	overflow: hidden;
	padding: 12px 0;

	.txt {
		flex: 1;
		overflow: hidden;
	}

	.h3 {
		font-size: 17px;
		color: #333;
		font-weight: 600;
		margin-bottom: 14px;
	}

	.p {
		font-size: 14px;
		color: #6a6868;
	}

	.img {
		flex-shrink: 0;
		width: 66px;
		margin-left: 10px;

		img {
			display: block;
			width: 100%;
		}
	}
}

.m-chobox {
	//min-height: 200px;

	.type {
		font-size: 14px;
		color: #818181;
		padding-bottom: 14px;
	}

	.line {
		margin-bottom: 10px;
	}

	.df {
		display: flex;
		justify-content: space-between;

		.item:nth-child(1) {
			width: 68.5%;
		}

		.item:nth-child(2) {
			width: 27.5%;
		}
	}

	--el-border-radius-base: 0;
}

.ul-hisb1 {
	font-size: 0;

	li {
		display: inline-block;
		vertical-align: top;
		margin-right: 60px;
		margin-bottom: 10px;
	}

	.a {
		cursor: pointer;
		font-size: 14px;
		color: #303030;
		transition: 0.4s;

		&:hover {
			color: #ff6a00;
		}
	}
}

.severity {
	padding-left: 14px;
	position: relative;
	color: #313133;

	&::before {
		content: "";
		display: block;
		width: 7px;
		height: 7px;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -3px;
	}

	&.lev1 {
		&::before {
			background-color: #e1504a;
		}
	}

	&.lev2 {
		&::before {
			background-color: #ff9006;
		}
	}

	&.lev3 {
		&::before {
			background-color: #06b0ff;
		}
	}

	&.lev4 {
		&::before {
			background-color: #32e6aa;
		}
	}
}

.g-sel_inp {
	display: flex;
	align-items: center;
}

.opts {
	display: flex;
	align-items: center;
	font-size: 18px;
	margin: auto 0;
	color: #888888;
	cursor: pointer;

	.set {
		margin-right: 12px;
	}
}

.m-leftbox,
.m-flexbox {
	display: flex;
	align-items: center;
}

.m-flexbox {
	margin-bottom: 30px;
}

.m-leftbox {
	.item {
		margin-right: 24px;
	}
}

.g-sel_inp {
	display: flex;
	align-items: center;
	--el-border-radius-base: 0;

	.el-select {
		margin-right: -1px;
		width: 120px;
		flex-shrink: 0;
	}

	.el-select__wrapper.is-focused {
		z-index: 2;
	}
}

.m-chotop {
	margin-bottom: 16px;

	.left {
		display: flex;
		align-items: center;
		flex: 1;
	}

	.el-button--primary {
		margin-right: 24px;
	}

	--el-border-radius-base: 0;

	.el-select {
		width: 290px;
	}
}

.el-button:active {
	z-index: 1;
}

:deep(.el-tabs__nav-wrap::after) {
	position: static !important;
}

@media (min-width: 500px) {
	.h3 {
		//color:blue;
		font-size: 12px;
	}

	.m-userbox {
		.user {
			.img {
				width: 30px;
				height: 30px
			}

			.name {
				font-size: 10px;
			}

			.id {
				font-size: 10px
			}
		}

		.info {
			font-size: 10px;
		}
	}

	.ul-listb1 {
		.con {
			.p {
				font-size: 12px;
			}

			.num {
				font-size: 15px;
			}
		}
	}

	.g-tabsb1 .con {
		font-size: 12px
	}

	.m-chobox .type {
		font-size: 12px
	}
}

@media (min-width: 1600px) {
	.h3 {
		//color:red;
		font-size: 20px;
	}

	.m-userbox {
		.user {
			.img {
				width: 50px;
				height: 50px
			}

			.name {
				font-size: 15px
			}

			.id {
				font-size: 15px
			}
		}

		.info {
			font-size: 15px
		}
	}

	.ul-listb1 {
		.con {
			.p {
				font-size: 17px
			}

			.num {
				font-size: 20px
			}
		}
	}

	.g-tabsb1 .con {
		font-size: 17px
	}

	.m-chobox .type {
		font-size: 15px
	}
}

@media (min-width: 2000px) {
	.h3 {
		//color:green;
		font-size: 25px;
	}

	.m-userbox {
		.user {
			.img {
				width: 70px;
				height: 70px
			}

			.name {
				font-size: 19px;
			}

			.id {
				font-size: 19px
			}
		}

		.info {
			font-size: 19px;
		}
	}

	.ul-listb1 {
		.con {
			.p {
				font-size: 22px
			}

			.num {
				font-size: 25px
			}
		}
	}

	.g-tabsb1 .con {
		font-size: 22px
	}

	.m-chobox .type {
		font-size: 22px
	}
}
</style>